<template>
  <div class="page">
    <div class="tip">
      <p>
        说明：商品导入一般需要几到十几分钟，完成时会提示和自动刷新页面，并新增导入操作记录，导入完成之前请勿关闭页面或在此页面进行其他操作，以免造成数据错误。
      </p>
      <a-button type="primary"> 数据导入 </a-button>
    </div>
    <div class="search__box">
      <a-form-model
        :model="form"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
        layout="inline"
      >
        <a-row>
          <a-col span="6">
            <a-form-model-item label="批次号">
              <a-input class="w220" v-model="form.name" />
            </a-form-model-item>
          </a-col>
          <a-col span="8">
            <a-form-model-item label="操作时间">
              <a-range-picker :placeholder="['开始时间', '结束时间']">
              </a-range-picker>
            </a-form-model-item>
          </a-col>

          <a-col span="4">
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button type="primary" @click="onSubmit"> 筛选 </a-button>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      bordered
    >
      <span slot="action" slot-scope="text, record">
        <div class="action_custom">
          <a-icon type="delete" @click="deleteHandle(record)" />
          <a-icon type="close-circle" />
          <a-icon type="arrow-down" />
        </div>
      </span>
    </a-table>
  </div>
</template>

<script>
const columns = [
  {
    title: "商品信息",
    dataIndex: "age",
    key: "age",
  },
  {
    title: "用户信息",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "评价内容",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "评价回复",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "评价时间",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "匿名评价",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "评价奖励",
    dataIndex: "address",
    key: "address",
  },
  {
    title: "操作",
    key: "action",
    scopedSlots: { customRender: "action" },
  },
];

const data = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sidney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
export default {
  name: "GoodsImport",
  data() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      form: {
        name: "",
        region: undefined,
        date1: undefined,
        delivery: false,
        type: [],
        resource: "",
        desc: "",
      },
      data,
      columns,
      pagination: {
        total: 100,
        showTotal: (total, range) => {
          console.log(total, range);
          return `共 ${total} 条`;
        },
        showQuickJumper: true,
      },
    };
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.search__box {
  margin-bottom: 10px;
}
.action_custom {
  color: #1890ff;
  font-size: 20px;
  > i {
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
}
.tip {
  padding: 20px;
  background: #ddd;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  > p {
    margin: 0;
  }
  margin-bottom: 10px;
}
</style>